import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import UserLockout from './../components/widgets/user_lockout';
import Layout from '../components/layouts/Layout';
import SEO from '../components/seo';
import BackButton from '../components/widgets/back';
import Scanner from '../components/enter/scanner';
import { getUrl } from '../utils/header_url_provider';
import { isApp } from '../utils';

import '../components/enter/style.scss';
import { getHTML, getText } from '../utils/cms_copy_provider';

export const Entry = ({ user, notification, config, actions }) => {
    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='Enter Now' />
            <UserLockout>
                <div className='enter buttons primary-page-content'>
                    <div className='row back-button-row'>
                        <div className='col-md-8 offset-md-2'>
                            <BackButton />
                        </div>
                    </div>
                    <div className='row top-row'>
                        <div className='col-md-8 offset-md-2'>
                            <div className='title'>
                                <h2>{getText('enter_now_title')}</h2>
                            </div>
                            {!isApp() && (
                                <>
                                    <div>{getHTML('enter_now_app_description')}</div>
                                    <div className='app-download-btn'>
                                        <a
                                            href={getUrl('Download on the App Store') || 'https://itunes.apple.com/us/app/pa-lottery-official-app/id910842501?mt=8'}
                                            target='_blank'
                                            rel='noreferrer'>
                                            <img alt='Download on the App Store' role='presentation' src='/assets/images/Apple_App_Store.png' />
                                        </a>
                                        &nbsp;&nbsp;&nbsp;
                                        <a
                                            href={getUrl('Get It On Google Play') || 'https://play.google.com/store/apps/details?id=com.sgi.palottery'}
                                            target='_blank'
                                            rel='noreferrer'>
                                            <img alt='Get It On Google Play' role='presentation' src='/assets/images/Get_it_on_Google_play.png' />
                                        </a>
                                    </div>
                                    <div>{getHTML('enter_now_description')}</div>
                                </>
                            )}
                        </div>
                    </div>

                    {isApp() && (
                        <div className='row'>
                            <div className='col-md-4 offset-md-4'>
                                <Scanner />
                            </div>
                        </div>
                    )}
                    <div className='row'>
                        <div className='col-md-4 offset-md-4'>
                            <Link className='theme-btn' to='/enter_draw'>
                                {getText('enter_btn_drawgame')}
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 offset-md-4'>
                            <Link className='theme-btn' to='/enter_fastplay'>
                                {getText('enter_btn_fastplaygame')}
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 offset-md-4'>
                            <Link className='theme-btn' to='/enter_scratch'>
                                {getText('enter_btn_scratchgame')}
                            </Link>
                        </div>
                    </div>
                </div>
            </UserLockout>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
